<template>
  <v-layout wrap>
    <v-flex xs12 class="title">
      <h2 :style="{ color: lastIntervalInHours > 24 ? 'red' : 'black' }">
        Activity to image {{ lastIntervalInHours ? `[${lastIntervalInHours}h.]` : '' }} 
      </h2>
    </v-flex>
    <template v-for="(item, index) of cronData">
      <v-flex xs4 :key="`lmd${index}`" class="item" @click="handleExpand(index)">
        {{ item.LMD.split('.').shift().replace('T', '  🕒 ') }}
      </v-flex>
      <v-flex xs4 :key="`total${index}`" class="item" @click="handleExpand(index)">
        {{ item.Result.result ? item.Result.result.length : '' }}
      </v-flex>
      <v-flex xs4 :key="`duration${index}`"  class="item" @click="handleExpand(index)">
        ⏱ {{ item.Result.durationTotal }}
      </v-flex>
      <v-flex xs12 v-if="index === selectedItem" style="padding:5px;" :key="`result${index}`">
        <p
          v-for="(result, index) of item.Result.result"
          :key="index"
        >
          {{result.name}}
          <br />
          <small><a :href="result.xml">{{result.xml}}</a></small>
          <br />
          <v-img :src="result.mediaURLs[0]" />
        </p>
      </v-flex>
    </template>
    <v-flex xs12 class="action">
      <a @click="handleGetCronResult">refresh</a>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from 'axios'
export default {
  data: () => ({
    lastIntervalInHours: null,
    cronData: [],
    selectedItem: null,
  }),
  mounted () {
    this.handleGetCronResult()
  },
  methods: {
    handleGetCronResult () {
      axios.get(`https://www.dviewcontent.com/api/public/cron/activity-to-image-result/`)
        .then(response => {
          if (!response) return 
          this.cronData = response.data.data
          this.lastIntervalInHours = (response.data.time / 60).toFixed(2)
        })
    },
    getIntervalInHours (v) {
      return parseInt((((new Date() - v) / 60000)))
    },
    handleExpand (v) {
      if (this.selectedItem === v) this.selectedItem = null
      else this.selectedItem = v
    },
  },
}
</script>
<style scoped>
.title {
  background-color: #ccc;
  text-align: center;
  padding: 20px 10px;
}
.item {
  text-align: center;
  padding: 20px 10px;
  border-bottom: 1px solid #CCCCCC80;
}
.action {
  padding: 10px;
  text-align: right;
}
</style>

